const config = {
  DOMAIN: process.env.DOMAIN || "http://127.0.0.1:1337",
  API_DOMAIN: process.env.API_DOMAIN || "",
  GTM_ID: process.env.GTM_ID || "",
  ENV: process.env.ENV || "development",
  GRAPHQL_URL: process.env.GRAPHQL_URL || "",
  DEV: process.env.DEV || "",
  CONVERTKIT_API_KEY: process.env.CONVERTKIT_API_KEY || "",
  CONVERTKIT_FORM_ID: process.env.CONVERTKIT_FORM_ID || "",
  GRAPHQL_SUPABASE_URL: process.env.GRAPHQL_SUPABASE_URL || "",
  GRAPHQL_SUPABASE_ANON_KEY: process.env.GRAPHQL_SUPABASE_ANON_KEY || "",
  GRAPHQL_SUPABASE_SERVICE_KEY: process.env.GRAPHQL_SUPABASE_SERVICE_KEY || "",
  GROQ_API_KEY: process.env.GROQ_API_KEY || "",
  YOUTUBE_API_KEY: process.env.YOUTUBE_API_KEY || "",
};
export default config;
