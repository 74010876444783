module.exports = {
  black: "#000000",
  white: "#ffffff",
  primary: "#B9A38A",
  secondary: "#cc910f",
  grey: {
    1: "#414241",
    2: "#DAD9D6",
  },
  link: "rgb(139 92 246/var(--tw-text-opacity))",
  react: "#5ccfee",
};
